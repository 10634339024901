* {
  margin: 0;
  padding: 0;
  line-height: 1;
  box-sizing: border-box;
  backface-visibility: hidden;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

@font-face {
  font-family: 'Theinhardt-sensei';
  src: url(../assets/fonts/Theinhardt-Light.woff2) format('woff2');
  font-weight: 300;
  font-style: normal;
}
@font-face {
  font-family: 'Theinhardt-sensei';
  src: url(../assets/fonts/Theinhardt-Regular.woff2) format('woff2');
  font-weight: 400;
  font-style: normal;
}
@font-face {
  font-family: 'Theinhardt-sensei';
  src: url(../assets/fonts/Theinhardt-Medium.woff2) format('woff2');
  font-weight: 500;
  font-style: normal;
}
.theinhardt {
  font-family: 'Theinhardt-sensei', sans-serif;
}

$w: 8640px;
$h: 4096px;

.theinhardt {
  font-family: 'Theinhardt-sensei';
}
* {
  @extend .theinhardt;
}
html {
  width: 100%;
  height: 100%;
  display: flex;
  font-weight: 300;
  align-items: center;
  justify-content: center;
  background: #222;
  &.zoom {
    zoom: 0.1875; // TODO: (1/4)^n multiple
  }
}
strong {
  font-weight: 500;
}
.logo {
  position: absolute;
  top: 320px;
  left: 280px;
  z-index: 2;
  mask-size: contain;
  object-fit: contain;
  background-size: contain;
}
.wall {
  overflow: hidden;
  // If we work in a fixed 4320x1920 we use zoom: 0.5~ to view the entire thing.
  position: relative;
  width: $w;
  height: $h;
  background: rgba(0, 0, 0, 1);
}

video {
  object-fit: cover;
}
